<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require(`@/assets/images/logos/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
              max-height="auto"
              max-width="250px"
              alt="logo"
              contain
              class=""
            ></v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-light text--primary text-center mb-0">Recuperar Senha!</p>
          <p class="text-center mb-2">Para recuperar a sua senha insira o seu e-mail cadastrado no sistema.</p>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="email"
            type="email"
            outlined
            label="E-mail"
            placeholder="nome@daher.adm.br"
            hide-details
            class="mb-3"
            @keyup.enter="changePassword"
          ></v-text-field>

          <v-btn
            @click="changePassword"
            :loading="loading"
            :disabled="block || loading"
            block
            x-large
            color="primary"
            class="mt-6"
          >
            Enviar
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import auth from "@/services/auth";

export default {
  data: () => ({
    email: "",
    loading: false,
    block: false
  }),
  methods: {
    async changePassword() {
      try {
        if (!this.email) throw new Error("Preencha o campo e-mail.");
        this.loading = true;
        await auth.resetPassword.requestCode(this.email);
        this.$toast.fire({
          title: "Link para redefinição de senha enviado para seu e-mail.",
          icon: "success"
        });
        this.block = true;
      } catch (err) {
        this.$toast.fire({
          title: err.message,
          icon: "error"
        });
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    email(val) {
      this.block = false;
    }
  },
  mounted() {
    this.email = this.$route.query.email;
  }
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
